var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "EditLayout",
    {
      attrs: {
        "supports-photo": "",
        item: _vm.item,
        "submit-button-text": _vm.isNew ? _vm.$t("create") : _vm.$t("save"),
        photo: _vm.photo,
      },
      on: {
        submit: function ($event) {
          _vm.isNew ? _vm.create() : _vm.edit()
        },
      },
    },
    [
      _c("div", { attrs: { slot: "sidebar-middle" }, slot: "sidebar-middle" }, [
        _c(
          "div",
          [
            _c("BaseInput", {
              attrs: { label: _vm.$t("position"), disabled: "" },
              model: {
                value: _vm.item.position,
                callback: function ($$v) {
                  _vm.$set(_vm.item, "position", $$v)
                },
                expression: "item.position",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("BaseInput", {
              attrs: {
                label: _vm.$tc("link"),
                placeholder: _vm.$t("enterLink"),
              },
              model: {
                value: _vm.item.link,
                callback: function ($$v) {
                  _vm.$set(_vm.item, "link", $$v)
                },
                expression: "item.link",
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }