import { render, staticRenderFns } from "./_id.vue?vue&type=template&id=1c8ebfe5"
import script from "./_id.vue?vue&type=script&lang=js"
export * from "./_id.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/game-medium-admin/game-medium-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1c8ebfe5')) {
      api.createRecord('1c8ebfe5', component.options)
    } else {
      api.reload('1c8ebfe5', component.options)
    }
    module.hot.accept("./_id.vue?vue&type=template&id=1c8ebfe5", function () {
      api.rerender('1c8ebfe5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/Slideshow/_id.vue"
export default component.exports